<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <div v-if="tagLine" class="tag-line" v-html="tagLine" />
    <v-select
      :id="id"
      v-model="formValue"
      :placeholder="placeholder"
      :options="options"
      :clearable="clearable"
      :loading="loading"
      :disabled="disabled"
      :append-to-body="appendToBody"
      :state="state"
      label="text"
      :selectable="selectable"
      :reduce="option => option.value"
      :filter-by="filterBy"
      :close-on-select="closeOnSelect"
      :calculate-position="calculatePosition"
      @close="$emit('close')"
      @open="$emit('open')"
    >
      <template #option="option">
        <slot name="option" :option="option" />
      </template>
      <template #spinner>
        <b-spinner
          v-if="loading"
          variant="primary"
          label="Spinning"
          small
        />
      </template>
    </v-select>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormValidFeedback,
  BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BSpinner,
    BFormGroup,
    BFormValidFeedback,
    BFormInvalidFeedback,
    vSelect,
  },
  props: {
    // Got value from v-model
    value: {
      type: [String],
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: new Array([]),
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    tagLine: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    column: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: null,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    selectable: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: option => true,
    },
    filterBy: {
      type: Function,
      default(option, label, search) {
        return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      },
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    calculatePosition: {
      type: Function,
      default(dropdownList, component, { width, top, left }) {
        // eslint-disable-next-line no-param-reassign
        dropdownList.style.top = top
        // eslint-disable-next-line no-param-reassign
        dropdownList.style.left = left
        // eslint-disable-next-line no-param-reassign
        dropdownList.style.width = width
      },
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value !== null) {
          this.$emit('input', value)
        } else {
          this.$emit('input', '')
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-line {
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
  margin-bottom: 0.75rem;
}
</style>
