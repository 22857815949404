<template>
  <div class="table-status text-muted">
    Showing {{ perPage > totalRows ? totalRows : perPage }} of {{ totalRows }} {{ totalRows &lt; 2 ? 'entity' : 'entities' }}
  </div>
</template>

<script>
export default {
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>
.table-status {
  font-size: smaller;
  text-align: right;
  margin: 4px;
}
</style>
